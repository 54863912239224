
 <script>
export default {
  methods: {
    getEmo (res) {
      var textArea = document.getElementById('comment-input');
      function changeSelectedText (obj, str) {
        if (window.getSelection) {
          // 非IE浏览器
          textArea.setRangeText(str);
          // 在未选中文本的情况下，重新设置光标位置
          textArea.selectionStart += str.length;
          textArea.focus()
        } else if (document.selection) {
          // IE浏览器
          obj.focus();
          var sel = document.selection.createRange();
          sel.text = str;
        }
      }
      changeSelectedText(textArea, res);
      this.content = textArea.value;// 要同步data中的数据
    }
  }
}
</script>