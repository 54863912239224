/* 
 * @Author: 曹俊杰 
 * @Date: 2021-01-16 10:14:38
 * @Module: 表情选择
 */
<template>
  <div class="chatIcon">
    <el-popover placement="top-start"
                width="400"
                trigger="click"
                class="emoBox">
      <div class="emotionList-box">
        <div class="emotionList">
          <a href="javascript:void(0);"
             @click="getEmo(index)"
             v-for="(item,index) in faceList"
             :key="index"
             class="emotionItem">{{item}}</a>
        </div>
      </div>
      <el-button icon="iconfont iconsmile"
                 size="mini"
                 circle
                 type="text"
                 slot="reference"></el-button>
    </el-popover>
  </div>
</template>
<script>
const appData = require("./emoji.json");
export default {
  components: {},
  data () {
    return {
      faceList: [],
    };
  },
  mounted () {
    for (let i in appData) {
      this.faceList.push(appData[i].char);
    }
  },
  methods: {
    getEmo (index) {
      this.$emit("getEmo", this.faceList[index])
    },
  },
};
</script>
<style scoped>
.emotionList-box {
  height: 200px;
  width: 400px;
  overflow: scroll;
  overflow-x: auto;
}
.chatIcon {
  padding: 0 10px;
  font-size: 25px;
}
.emotionList {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
}
.emotionItem {
  width: 50px;
  font-size: 20px;
  text-align: center;
}
/*包含以下四种的链接*/
.emotionItem {
  text-decoration: none;
}
/*正常的未被访问过的链接*/
.emotionItem:link {
  text-decoration: none;
}
/*已经访问过的链接*/
.emotionItem:visited {
  text-decoration: none;
}
/*鼠标划过(停留)的链接*/
.emotionItem:hover {
  text-decoration: none;
}
/* 正在点击的链接*/
.emotionItem:active {
  text-decoration: none;
}
</style>